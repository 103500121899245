export const customizeFormElements = container => {
  const elements = container.querySelectorAll('.form-group')

  let delay = getComputedStyle(container).getPropertyValue('--delay')
  delay = parseFloat(delay)

  let total = 0

  elements.forEach((element, i) => {
    total += 1
    element.classList.add('input-block', 'fade-in-up', 'mb-16')
    element.style.setProperty('--delay', `${delay + i / 10}s`)

    element.setAttribute('data-validateable-block', '')

    const label = element.querySelector('.control-label')

    if (label) {
      label.classList.remove('control-label')
      label.classList.add('input-label')
    }

    const field = element.querySelector('.form-control')

    if (field) {
      field.classList.remove('form-control')
      field.classList.add('input-field')
      field.setAttribute('data-validateable-input', '')
    }

    // required input
    const span = element.querySelector('span.text-muted.small')

    if (span) {
      element.removeChild(span)

      if (label) {
        label.innerText += '*'
      }

      if (field) {
        field.setAttribute('required', '')
      }
    }

    // empty options
    const emptyOption = element.querySelector('option[value=""]')

    if (emptyOption) {
      emptyOption.innerHTML = '&nbsp;'
    }
  })

  const legends = container.querySelectorAll('legend')

  legends.forEach((element, i) => {
    total += 1
    element.classList.remove('control-label')
    element.classList.add('h5', 'fade-in', 'mb-16')
    element.style.setProperty('--delay', `${delay + total / 10 + i / 10}s`)
  })

  // fieldsets
  const fieldSets = container.querySelectorAll('fieldset')

  fieldSets.forEach(element => {
    element.parentNode.classList.add('has-fieldset', 'pt-32')
  })

  const checkboxContainers = container.querySelectorAll('.checkbox, .radio')

  // checkboxes
  checkboxContainers.forEach(element => {
    const isRadio = element.classList.contains('radio')

    element.parentNode.classList.add('checkbox-container')
    element.classList.remove('checkbox', 'radio')
    element.classList.add('mb-16', 'pt-8')

    const label = element.querySelector('label')
    const input = element.querySelector('input')

    if (label && input) {
      label.classList.add('checkbox')
      if (isRadio) {
        label.classList.add('checkbox_radio')
      }

      const text = label.textContent

      label.textContent = ''

      input.classList.add('checkbox__input')
      label.appendChild(input)

      const fake = document.createElement('span')
      fake.classList.add('checkbox__fake')

      if (isRadio) {
        fake.classList.add('checkbox__fake--radio')
      } else {
        fake.innerHTML = `
        <svg width='10' height='10' viewBox='0 0 10 10'>
          <polyline points='2,5 4,7 8,3' />
        </svg>
      `
      }

      label.appendChild(fake)

      const span = document.createElement('span')
      span.classList.add('checkbox__label')

      if (isRadio) {
        span.classList.add('checkbox__label_radio')
      }

      span.innerHTML = text
      label.appendChild(span)
    }
  })

  // primary buttons
  const primaryButtons = container.querySelectorAll('.btn.btn-primary')

  primaryButtons.forEach(button => {
    button.classList.add(
      'cta',
      'cta_full-width',
      'cta__braun',
      'text-small',
      'text-small_16',
      'cta_submit-progress'
    )

    const wrapper = document.createElement('div')
    wrapper.classList.add('fade-in-up')
    wrapper.style.setProperty('--delay', `${delay + total / 10 + 0.1}s`)

    button.parentNode.appendChild(wrapper)

    button.innerHTML = `
      <span class="cta__text">
        ${button.textContent}
      </span>
    `

    wrapper.appendChild(button)
  })

  if ('animatedFormSubmission' in container.dataset) {
    const forms = container.querySelectorAll('form')

    forms.forEach(form => {
      form.setAttribute('data-animated-submission', '')
    })
  }

  if ('text' in container.dataset) {
    const fieldset = container.querySelector('fieldset')

    if (fieldset) {
      const span = document.createElement('span')
      span.classList.add('form-text', 'fade-in-up')
      span.innerText = container.dataset.text

      fieldset.prepend(span)
    }
  }
}
